import React, { Fragment } from 'react';
import TopBar from './TopBar';

const Landing = () => {
  return (
    <Fragment>
      <div className='home-wrapper'>
        <div className='home-top-section'>
          <div className='intro-landing'>
            {/* <div className='bio-pic-wrapper'>
              <img
                className='bio-pic'
                src={require('../../assets/images/landing/ship.png')}
                alt='fruit-ninja'
              />
            </div> */}
            <div className='bio-wrapper font-domine'>
              <p className='name'>Kelvin Yu</p>
              <TopBar />
              <p className='bio'>
                I live in DC, where I invest in startups advancing national
                security & technological frontiers at{' '}
                <a
                  href='https://www.iqt.org'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  IQT
                </a>
                . I am also a Non-Resident Fellow at the Foundation for American
                Innovation, where I research defense innovation and{' '}
                <a
                  href='https://www.leversforprogress.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  techno-industrial policy
                </a>
                .
              </p>
              <p className='bio'>
                I most recently led AI policy on the House Science Committee,
                which oversaw the $50B federal R&D budget. Before DC, I was
                founding engineer #1 at two startups and an early-stage investor
                in the Bay Area. I studied CS and International Relations at
                Princeton before dropping out to build a company.
              </p>
              <p className='bio'>
                If you are puzzled why there are no references to Hollywood or
                good-looking men on this site, you are likely looking for Kelvin
                Yu the actor & writer. You may also be wondering whether I am a
                profesional golfer, in which case you are probably thinking of
                Kevin Yu. My parents had the good sense to differentiate me
                against the oversupply of Kevin's, but their solution often
                causes confusion—hence why I am considering bringing "Lord
                Kelvin" back.
              </p>
              <p className='bio'>
                Outside of my immediate work, I enjoy stochastic competitions,
                organizing reading groups, and taking (unnecessarily) extensive
                notes. Current topics of interest include: technological
                inflection points, the dialectic between technology and cultural
                notions of "progress," and critiques of modernity. Reach out if
                you'd like to trade notes:{' '}
                <code>".".join(["moc","liamg@lanosrep.uy.nivlek"])[::-1]</code>
              </p>
            </div>
          </div>
        </div>
        <div className='home-bottom-section'>
          <div className='w-full flex flex-wrap md:p-0 md:pt-0 lg:p-0 lg:pt-0'>
            <div className='w-1/2 md:w-1/3 p-6 card rounded'>
              <a
                href='https://kelvinotcelsius.substack.com/p/atomic-transactions-where-is-my-flying'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p className='content-title'>Atomic Transactions</p>
                <p className='content-subtitle'>
                  Why Some Deeptech Categories Die More Than Others
                </p>
              </a>
            </div>
            <div className='w-1/2 md:w-1/3 p-6 card rounded'>
              <a
                href='https://kelvinotcelsius.substack.com/p/unleash-the-techno-industrial-state'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p className='content-title'>
                  Unleash the Techno-Industrial State
                </p>
                <p className='content-subtitle'>
                  An Industrial Policy Vision for the Next Administration
                </p>
              </a>
            </div>
            <div className='w-1/2 md:w-1/3 p-6 card rounded'>
              <a
                href='https://www.leversforprogress.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p className='content-title'>Levers for Progress</p>
                <p className='content-subtitle'>
                  How States Can Advance Scientific and Technological Progress
                </p>
              </a>
            </div>
            <div className='w-1/2 md:w-1/3 p-6 card rounded'>
              <a
                href='https://kelvinotcelsius.substack.com/p/notes-from-the-underground-founder'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p className='content-title'>
                  Notes from the Underground Founder
                </p>
                <p className='content-subtitle'>
                  Silicon Valley Superfluousness
                </p>
              </a>
            </div>
            <div className='w-1/2 md:w-1/3 p-6 card rounded'>
              <a
                href='https://kelvinotcelsius.substack.com/p/how-nasa-builds-political-capital'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p className='content-title'>
                  How NASA Builds Political Capital
                </p>
                <p className='content-subtitle'>
                  Five Tactics for Generating Public Support for Ambitious
                  Projects
                </p>
              </a>
            </div>
            <div className='w-1/2 md:w-1/3 p-6 card rounded'>
              <a
                href='https://kelvinotcelsius.substack.com/p/agentic-theories-of-history-and-the'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p className='content-title'>
                  Anti-Agentic Theories of History
                </p>
                <p className='content-subtitle'>
                  The False Seduction of Cyclicality
                </p>
              </a>
            </div>
            {/* <div className='w-1/2 md:w-1/3 p-6 card rounded'>
              <a
                href='https://kelvinotcelsius.substack.com/p/why-startup-m-and-a-is-vital-for'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p className='content-title'>
                  Why Startup M&A Is Vital For Defense Innovation
                </p>
                <p className='content-subtitle'>
                  The Upsides of Startup M&A Activity for National Security
                </p>
              </a>
            </div> */}
          </div>
        </div>
        {/* <div id='mc_embed_signup'>
          <iframe
            src='https://kelvinotcelsius.substack.com/embed'
            width='480'
            height='320'
            // style='border:1px solid #EEE; background:white;'
            frameborder='0'
            scrolling='no'
            title='substack'
          ></iframe>
        </div> */}
      </div>
    </Fragment>
  );
};

export default Landing;
