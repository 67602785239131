import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import TopBar from './TopBar';

const ProtectedPage = () => {
  const [display, setDisplay] = useState(false);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');
  var matches = ['keith', 'eric'];
  var errorMsgs = [
    'Unauthorized access...computer shutting down in 5 seconds 🤯',
    'Someone is trying to be the naughty 😏',
    '故春非我春，夏非我夏，秋非我秋，冬非我冬',
    'Veni, vidi, veci.',
    'There is nothing hard about the path one has chosen to take',
    'Rest in the end, not the middle.',
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    var lowercasedPassword = password.toLowerCase();
    var found = matches.indexOf(lowercasedPassword);
    if (found !== -1) {
      const element = document.querySelector('.password-form');
      element.classList.add('animate__animated', 'animate__bounceOutLeft');
      setDisplay(true);
      setError('');
    } else {
      setDisplay(false);
      setError(errorMsgs[Math.floor(Math.random() * errorMsgs.length)]);
    }
    console.log(display);
  };

  return (
    <Fragment>
      <TopBar />
      <div className='intro-guide'>
        <div className='home-link'>
          <span>
            <Link to='/'>Home</Link>
          </span>
        </div>
        <div className='name-pic-row'>
          <span className='name-guide'>Kelvin Yu</span>
          {/* <img
            src={require('../../assets/images/me.svg')}
            alt='kelvin-face'
            width='74px'
          /> */}
        </div>
      </div>
      <div id='protected'>
        <div
          className={`guide-content animate__animated ${
            display ? 'show animate__backInRight' : 'hide'
          }`}
        >
          <div className='guide-body-container'>
            <div className='guide-section-wrapper'>
              <h2 className='guide-section-title animate__jello'>
                About Kelvin Yu
              </h2>
              <p className='guide-paragraph'>
                <span role='img' aria-label='waving emoji'>
                  👋
                </span>{' '}
                Hi! I'm Kelvin — I was born in Singapore, raised in Oregon, and
                am currently a CS junior at Princeton University.
              </p>
            </div>
          </div>
        </div>
        <form
          className={`password-form animate__animated ${
            !display ? 'animate__backInUp' : 'animate__bounceOutLeft'
          }`}
          onSubmit={(e) => onSubmit(e)}
        >
          <input
            type='text'
            placeholder='Unlock your page'
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <input className='btn-submit' type='submit'></input>
          <p className='form-error'>{error}</p>
        </form>
      </div>
    </Fragment>
  );
};

export default ProtectedPage;
