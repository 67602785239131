import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const TopBar = () => {
  return (
    <Fragment>
      <div className='topbar-wrapper'>
        <p id='topbar'>
          <a
            className='landing-page-link'
            href='https://www.twitter.com/kelvinotcelsius/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ paddingRight: '5px' /* paddingLeft: '5px' */ }}
          >
            Twitter
          </a>
          •
          <a
            className='landing-page-link'
            href='https://kelvinotcelsius.substack.com/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ paddingRight: '5px', paddingLeft: '5px' }}
          >
            Substack
          </a>
          •
          <a
            className='landing-page-link'
            href='https://www.linkedin.com/in/kelvinotcelsius/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ paddingRight: '5px', paddingLeft: '5px' }}
          >
            LinkedIn
          </a>
          •
          <a
            className='landing-page-link'
            href='https://www.goodreads.com/user/show/156772512-kelvin-yu'
            target='_blank'
            rel='noopener noreferrer'
            style={{ paddingRight: '5px', paddingLeft: '5px' }}
          >
            Goodreads
          </a>
          •{' '}
          <Link
            to='/angel'
            className='landing-page-link'
            target='_blank'
            rel='noopener noreferrer'
            style={{ paddingRight: '5px' }}
          >
            Angel Investments
          </Link>
        </p>
      </div>
    </Fragment>
  );
};

export default TopBar;
